import { apiCaller, Vue } from '../index'
import { joinStringArray } from '../../utils/commonUtils'
import { getNewIoField } from '../../utils/instructionsUtils'
import {
  AutocompleteParams,
  CommitStateType,
  IParamsToSend, ISearchedItems,
  ISearchedItemsSubType,
  ISearchParamsFilters,
  IState,
  SurcoucheV2Filters,
  CancelTokensDictType
} from '../../types/surcouche_v2_types'
import { ICountry } from '../../types/country_types'
import { setupAutocompleteInsertionOrder } from '@/components/SurcoucheV2/surcoucheV2utils'
import axios from 'axios'

const DEFAULT_LIMIT = 50

const cancelTokens: CancelTokensDictType = {
  company: null,
  siege: null,
  advertiser: null,
  campaign: null,
  insertionOrder: null
}

const state: IState = {
  surcoucheV2Dsp: '' as string,
  surcoucheV2SearchLoading: false as Boolean,
  surcoucheV2SearchedItemsLoading: {
    company: false,
    siege: false,
    advertiser: false,
    campaign: false,
    insertionOrder: false
  },
  surcoucheV2SearchCancelled: false as Boolean,
  surcoucheV2AutoSearch: '' as String,
  surcoucheV2SearchedItems: {
    companies: {} as ISearchedItemsSubType,
    sieges: {} as ISearchedItemsSubType,
    advertisers: {} as ISearchedItemsSubType,
    campaigns: {} as ISearchedItemsSubType,
    insertionOrders: {} as ISearchedItemsSubType
  } as ISearchedItems,
  surcoucheV2Countries: [],
  alertCategories: [],
  surcoucheV2SearchParamsFilters: { limit: DEFAULT_LIMIT, offset: 0 } as ISearchParamsFilters,
  surcoucheV2Filters: {} as SurcoucheV2Filters
}

const colsByCategory: { [key: string]: Array<string> } = {
  company: ['id', 'name'],
  siege: ['id', 'name', 'external_id'],
  advertiser: ['id', 'name', 'external_id'],
  campaign: ['id', 'name'],
  insertionOrder: ['id', 'group_name']
}

const actions = {
  async getV2ApiAlertCategories ({ commit, state }: CommitStateType) {
    const apiAlertCategories = await apiCaller.getAlertCategories()
    if (apiCaller.isResponseError(apiAlertCategories)) {
      console.warn('Error when getting alert categories')
      commit('setErrorMessageWithResponse', apiAlertCategories)
    } else {
      commit('setSurcoucheV2AlertCategoriesItems', apiAlertCategories.data.results)
    }
  },
  async getV2ApiCountries ({ commit, state }: CommitStateType) {
    const apiCountries = await apiCaller.getCountries()
    if (apiCaller.isResponseError(apiCountries)) {
      console.warn('Error when getting alert categories')
      commit('setErrorMessageWithResponse', apiCountries)
    } else {
      const sortedCountries = sortCountriesByName(apiCountries.data)
      commit('setSurcoucheV2CountriesItems', sortedCountries)
    }
  },
  async setSearchedCompanies ({ commit, state }: CommitStateType) {
    commit('setSurcoucheV2CompaniesLoading', true)
    const companiesData: IParamsToSend = createParamsToSendForSearchedItemsObject(
      state.surcoucheV2SearchParamsFilters,
      'company'
    )
    const responseCompanies = await apiCaller.getCompanies(companiesData, cancelTokens.company)
    if (apiCaller.isResponseError(responseCompanies)) {
      console.warn('Error when getting companies')
      commit('setErrorMessageWithResponse', responseCompanies)
    } else if (!apiCaller.isCancelError(responseCompanies)) {
      let entities: Array<{ [key: string]: any }> = []
      if ('data' in responseCompanies && responseCompanies.data != null) {
        responseCompanies.data.forEach((line: any) => {
          entities.push({ ...line, type: 'company' })
        })
      }
      commit('setSurcoucheV2CompaniesSearchResults', {
        entities: entities,
        type: 'company',
        count: responseCompanies.count,
        limit: responseCompanies.limit,
        offset: responseCompanies.offset
      })
      commit('setSurcoucheV2CompaniesLoading', false)
      commit('setSurcoucheV2SearchLoading', !Object.values(state.surcoucheV2SearchedItemsLoading).every(v => v === false))
    }
  },
  async setSearchedSieges ({ commit, state }: CommitStateType) {
    commit('setSurcoucheV2SiegesLoading', true)
    const siegesData: IParamsToSend = createParamsToSendForSearchedItemsObject(
      state.surcoucheV2SearchParamsFilters,
      'siege'
    )
    const responseSieges = await apiCaller.getSieges(siegesData, cancelTokens.siege)
    if (apiCaller.isResponseError(responseSieges)) {
      console.warn('Error when getting sieges')
      commit('setErrorMessageWithResponse', responseSieges)
    } else if (!apiCaller.isCancelError(responseSieges)) {
      let entities: Array<{ [key: string]: any }> = []
      if ('data' in responseSieges && responseSieges.data != null) {
        responseSieges.data.forEach((line: any) => {
          entities.push({ ...line, type: 'siege' })
        })
      }
      commit('setSurcoucheV2SiegesSearchResults', {
        entities: entities,
        type: 'siege',
        count: responseSieges.count,
        limit: responseSieges.limit,
        offset: responseSieges.offset
      })
      commit('setSurcoucheV2SiegesLoading', false)
      commit('setSurcoucheV2SearchLoading', !Object.values(state.surcoucheV2SearchedItemsLoading).every(v => v === false))
    }
  },
  async setSearchedAdvertisers ({ commit, state }: CommitStateType) {
    commit('setSurcoucheV2AdvertisersLoading', true)
    const advertisersData: IParamsToSend = createParamsToSendForSearchedItemsObject(
      state.surcoucheV2SearchParamsFilters,
      'advertiser'
    )
    const responseAdvertisers = await apiCaller.getAdvertisers(advertisersData, cancelTokens.advertiser)
    if (apiCaller.isResponseError(responseAdvertisers)) {
      console.warn('Error when getting advertisers')
      commit('setErrorMessageWithResponse', responseAdvertisers)
    } else if (!apiCaller.isCancelError(responseAdvertisers)) {
      let entities: Array<{ [key: string]: any }> = []
      if ('data' in responseAdvertisers && responseAdvertisers.data != null) {
        responseAdvertisers.data.forEach((line: any) => {
          entities.push({ ...line, type: 'advertiser' })
        })
      }
      commit('setSurcoucheV2AdvertisersSearchResults', {
        entities: entities,
        type: 'advertiser',
        count: responseAdvertisers.count,
        limit: responseAdvertisers.limit,
        offset: responseAdvertisers.offset
      })
      commit('setSurcoucheV2AdvertisersLoading', false)
      commit('setSurcoucheV2SearchLoading', !Object.values(state.surcoucheV2SearchedItemsLoading).every(v => v === false))
    }
  },
  async setSearchedCampaigns ({ commit, state }: CommitStateType) {
    commit('setSurcoucheV2CampaignsLoading', true)
    const campaignsData: IParamsToSend = createParamsToSendForSearchedItemsObject(
      state.surcoucheV2SearchParamsFilters,
      'campaign'
    )
    const responseCampaigns = await apiCaller.getCampaigns(campaignsData, cancelTokens.campaign)
    if (apiCaller.isResponseError(responseCampaigns)) {
      console.warn('Error when getting campaigns')
      commit('setErrorMessageWithResponse', responseCampaigns)
    } else if (!apiCaller.isCancelError(responseCampaigns)) {
      let entities: Array<{ [key: string]: any }> = []
      if ('data' in responseCampaigns && responseCampaigns.data != null) {
        responseCampaigns.data.forEach((line: any) => {
          entities.push({ ...line, type: 'campaign' })
        })
      }
      commit('setSurcoucheV2CampaignsSearchResults', {
        entities: entities,
        type: 'campaign',
        count: responseCampaigns.count,
        limit: responseCampaigns.limit,
        offset: responseCampaigns.offset
      })
      commit('setSurcoucheV2CampaignsLoading', true)
      commit('setSurcoucheV2SearchLoading', !Object.values(state.surcoucheV2SearchedItemsLoading).every(v => v === true))
    }
  },
  async setSearchedInsertionOrders ({ commit, state }: CommitStateType) {
    commit('setSurcoucheV2InsertionOrdersLoading', true)
    const insertionOrdersData: IParamsToSend = createParamsToSendForSearchedItemsObject(
      state.surcoucheV2SearchParamsFilters,
      'insertionOrder'
    )
    const responseInsertionOrders = await apiCaller.getSurcoucheV2InsertionOrders(insertionOrdersData, cancelTokens.insertionOrder)
    if (apiCaller.isResponseError(responseInsertionOrders)) {
      console.warn('Error when getting insertion orders')
      commit('setErrorMessageWithResponse', responseInsertionOrders)
    } else if (!apiCaller.isCancelError(responseInsertionOrders)) {
      const entities: Array<any> = []
      Object.keys(responseInsertionOrders.data).forEach((key: string) => {
        responseInsertionOrders.data[key].forEach((lineItem: { [key: string]: any }) => {
          entities.push({ ...lineItem, type: 'insertionOrder' })
        })
      })
      commit('setSurcoucheV2InsertionOrdersSearchResults', {
        entities: entities,
        type: 'insertionOrder',
        count: responseInsertionOrders.count,
        limit: responseInsertionOrders.limit,
        offset: responseInsertionOrders.offset
      })
      commit('setSurcoucheV2InsertionOrdersLoading', false)
      commit('setSurcoucheV2SearchLoading', !Object.values(state.surcoucheV2SearchedItemsLoading).every(v => v === false))
    }
  },
  async getSurcoucheV2AutocompleteItems ({ commit, state }: CommitStateType) {
    commit('setSurcoucheV2SearchGlobalLoading', true)
    commit('setSurcoucheV2SearchCancelled', false)

    const params: ISearchParamsFilters = state.surcoucheV2SearchParamsFilters

    console.warn('SurcoucheV2 store API GETs start...')

    // CANCEL TOKENS
    createCancelTokensForEachRequest()

    const functions: Array<{ type: string, method: (arg1: CommitStateType) => void, params: CommitStateType }> = [
      { type: 'company', method: actions.setSearchedCompanies, params: { commit, state } },
      { type: 'siege', method: actions.setSearchedSieges, params: { commit, state } },
      { type: 'advertiser', method: actions.setSearchedAdvertisers, params: { commit, state } },
      { type: 'campaign', method: actions.setSearchedCampaigns, params: { commit, state } },
      { type: 'insertionOrder', method: actions.setSearchedInsertionOrders, params: { commit, state } }
    ]

    for (const func of functions) {
      if (state.surcoucheV2SearchCancelled === false) {
        // Campaigns only if current DSP is DBM
        if (func.type === 'campaign' && state.surcoucheV2Dsp !== 'dbm') {
          continue
        } else {
          func.method(func.params)
        }
      }
    }
  },
  surcoucheV2SearchCancelled ({ commit, state }: CommitStateType) {
    commit('setSurcoucheV2SearchCancelled', true)
    Object.keys(cancelTokens as CancelTokensDictType).forEach((key: string) => {
      if (cancelTokens.hasOwnProperty(key) && cancelTokens[key as keyof CancelTokensDictType] != null) {
        cancelTokens[key as keyof CancelTokensDictType].cancel(`[okCancel] Autocomplete ${key} Search Cancelled`)
      }
    })
  },
  resetSurcoucheV2SearchedItems ({ commit, state }: CommitStateType) {
    console.log('debug in resetSurcoucheV2SearchedItems')
    const baseItems: ISearchedItems = {
      companies: {} as ISearchedItemsSubType,
      sieges: {} as ISearchedItemsSubType,
      advertisers: {} as ISearchedItemsSubType,
      campaigns: {} as ISearchedItemsSubType,
      insertionOrders: {} as ISearchedItemsSubType
    }
    commit('setSurcoucheV2AllSearchResults', baseItems)
    commit('setSurcoucheV2SearchGlobalLoading', false)
  },
  async getAutocompleteInsertionOrder ({ commit, state }: CommitStateType, params: AutocompleteParams) {
    const dsp = params.dsp
    const search = params.search
    const response = await apiCaller.getSurcoucheV2InsertionOrders({ search: search, dsp: dsp })
    if (apiCaller.isResponseError(response)) {
      console.warn('Error when getting alert categories')
      commit('setErrorMessageWithResponse', response)
    } else {
      const { autocompleteFilter, entities } = setupAutocompleteInsertionOrder(response.data, dsp, 'insertionOrder')
      commit('setSurcoucheV2InsertionOrdersSearchResults', entities)
      return { autocompleteFilter, entities }
    }
  }
}

const mutations = {
  setSurcoucheV2AlertCategoriesItems (state: IState, items: any) {
    Vue.set(state, 'alertCategories', items)
  },
  setSurcoucheV2CountriesItems (state: IState, items: any) {
    Vue.set(state, 'surcoucheV2Countries', items)
  },
  setSurcoucheV2Dsp (state: IState, items: any) {
    Vue.set(state, 'surcoucheV2Dsp', items)
  },
  setSurcoucheV2SearchParamsFilters (state: IState, value: { filter: string, value: string | number | Array<string> }) {
    Vue.set(state.surcoucheV2SearchParamsFilters, value.filter, value.value)
  },
  setSurcoucheV2AutoSearch (state: IState, value: string) {
    Vue.set(state, 'surcoucheV2AutoSearch', value)
  },
  setSurcoucheV2CompaniesSearchResults (state: IState, items: ISearchedItemsSubType) {
    Vue.set(state.surcoucheV2SearchedItems, 'companies', items)
  },
  setSurcoucheV2SiegesSearchResults (state: IState, items: ISearchedItemsSubType) {
    Vue.set(state.surcoucheV2SearchedItems, 'sieges', items)
  },
  setSurcoucheV2AdvertisersSearchResults (state: IState, items: ISearchedItemsSubType) {
    Vue.set(state.surcoucheV2SearchedItems, 'advertisers', items)
  },
  setSurcoucheV2CampaignsSearchResults (state: IState, items: ISearchedItemsSubType) {
    Vue.set(state.surcoucheV2SearchedItems, 'campaigns', items)
  },
  setSurcoucheV2InsertionOrdersSearchResults (state: IState, items: ISearchedItemsSubType) {
    Vue.set(state.surcoucheV2SearchedItems, 'insertionOrders', items)
  },
  setSurcoucheV2AllSearchResults (state: IState, items: ISearchedItems) {
    Vue.set(state.surcoucheV2SearchedItems, 'companies', items.companies)
    Vue.set(state.surcoucheV2SearchedItems, 'sieges', items.sieges)
    Vue.set(state.surcoucheV2SearchedItems, 'advertisers', items.advertisers)
    Vue.set(state.surcoucheV2SearchedItems, 'campaigns', items.campaigns)
    Vue.set(state.surcoucheV2SearchedItems, 'insertionOrders', items.insertionOrders)
  },
  // CANCEL
  setSurcoucheV2SearchCancelled (state: IState, value: boolean) {
    Vue.set(state, 'surcoucheV2SearchCancelled', value)
  },
  setSurcoucheV2CompaniesCancelToken (state: IState, value: boolean) {
    Vue.set(state, 'surcoucheV2SearchLoading', value)
  },
  // LOADING
  setSurcoucheV2SearchLoading (state: IState, value: boolean) {
    Vue.set(state, 'surcoucheV2SearchLoading', value)
  },
  setSurcoucheV2CompaniesLoading (state: IState, value: boolean) {
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'companies', value)
  },
  setSurcoucheV2SiegesLoading (state: IState, value: boolean) {
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'sieges', value)
  },
  setSurcoucheV2AdvertisersLoading (state: IState, value: boolean) {
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'advertisers', value)
  },
  setSurcoucheV2CampaignsLoading (state: IState, value: boolean) {
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'campaigns', value)
  },
  setSurcoucheV2InsertionOrdersLoading (state: IState, value: boolean) {
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'insertionOrders', value)
  },
  setSurcoucheV2SearchGlobalLoading (state: IState, value: boolean) {
    Vue.set(state, 'surcoucheV2SearchLoading', value)
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'company', value)
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'advertiser', value)
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'siege', value)
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'campaign', value)
    Vue.set(state.surcoucheV2SearchedItemsLoading, 'insertionOrder', value)
  },
  setSurcoucheV2Filters (state: IState, value: SurcoucheV2Filters) {
    Vue.set(state, 'surcoucheV2Filters', value)
  }
}

const getters = {
  getSurcoucheV2CountriesItems (state: IState) { return state.surcoucheV2Countries },
  getSurcoucheV2AlertCategoriesItems (state: IState) { return state.alertCategories },
  getSurcoucheV2SearchLoading (state: IState) { return state.surcoucheV2SearchLoading },
  getSurcoucheV2SearchCancelled (state: IState) { return state.surcoucheV2SearchCancelled },
  getSurcoucheV2SearchParamsFilters (state: IState) { return state.surcoucheV2SearchParamsFilters },
  getSurcoucheV2AutoSearch (state: IState) { return state.surcoucheV2AutoSearch },
  getSurcoucheV2SearchedItems (state: IState) { return state.surcoucheV2SearchedItems },
  getSurcoucheV2Filters (state: IState) { return state.surcoucheV2Filters }
}

export const surcoucheV2Store = {
  actions,
  mutations,
  state,
  getters
}

// utils
function createCancelTokensForEachRequest () {
  cancelTokens['company'] = axios.CancelToken.source()
  cancelTokens['siege'] = axios.CancelToken.source()
  cancelTokens['advertiser'] = axios.CancelToken.source()
  cancelTokens['campaign'] = axios.CancelToken.source()
  cancelTokens['insertionOrder'] = axios.CancelToken.source()
}
function resetAllCancelTokens () {
  cancelTokens['company'] = null
  cancelTokens['siege'] = null
  cancelTokens['advertiser'] = null
  cancelTokens['campaign'] = null
  cancelTokens['insertionOrder'] = null
}
function createParamsToSendForSearchedItemsObject (data: ISearchParamsFilters, type: string): IParamsToSend {
  let toSend: IParamsToSend = {}
  Object.keys(data).forEach((key: string) => {
    let element = data[key as keyof ISearchParamsFilters]
    if (element != null) {
      if (Array.isArray(element)) {
        toSend[key] = joinStringArray(element)
      } else {
        toSend[key] = element.toString()
      }
    }
  })
  toSend.cols = joinStringArray(colsByCategory[type])
  // Extra for insertionOrders
  if (type === 'insertionOrder') {
    toSend.cols = `${toSend.cols},${getNewIoField(state.surcoucheV2SearchParamsFilters.dsp)}`
  }
  return toSend
}

function sortCountriesByName (countries: Array<ICountry>) {
  return countries.sort((a: ICountry, b: ICountry) => a.name.localeCompare(b.name))
}
