import _ from 'lodash'
import {
  AutocompleteFilter, EntityModelSurcoucheV2, EntityType, SurcoucheV2Filters,
  SurcoucheV2Query,
  SurcoucheV2QueryModel, TabIndexType
} from '../../../types/surcouche_v2_types'
import { apiCaller } from '../../../store'
import UserModel from '@/models/Users/UserModel'
import {
  CollectionInstruction,
  DspInstruction,
  Instruction,
  InstructionDsp
} from '../../../types/instruction_type'
import { getIoField } from '../../../utils/instructionsUtils'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import SiegeModel from '@/models/Keystone_v2/SiegeModel'
import AdvertiserModel from '@/models/Keystone_v2/AdvertiserModel'

// set tabIndex type
export function getTabForEntity (entityType: EntityType, tabIndex: TabIndexType) {
  console.log('tabIndex', tabIndex)
  return tabIndex[entityType]
}

// set tabIndex type
export function getEntityForTab (nb: number, tabIndex: TabIndexType): EntityType {
  const keyOfTabIndex: EntityType[] = Object.keys(tabIndex) as EntityType[]
  return keyOfTabIndex.find((key: EntityType) => tabIndex[key] === nb)
}

// set entity type
export function getNameForEntity (entity: EntityModelSurcoucheV2, entityType: EntityType): string {
  switch (entityType) {
    case 'company':
      return (entity as CompanyModel).name
    case 'siege':
      return (entity as SiegeModel).name
    case 'advertiser':
      return (entity as AdvertiserModel).name
    case 'insertionOrder':
      return (entity as Instruction).group_name
    default:
      console.warn('Not implemented entityType')
  }
}

/**
 * @param query {SurcoucheV2Filters}
 * @param autocompleteFilter {AutocompleteFilter|null}
 * @return SurcoucheV2Filters
**/
export function setFiltersFromQuery (query: SurcoucheV2Filters, autocompleteFilter: AutocompleteFilter | null = null): SurcoucheV2Filters {
  const surcoucheV2Filters: SurcoucheV2Filters = _.cloneDeep(query)
  const exception: string[] = ['country', 'search', 'autocomplete']
  Object.keys(query).forEach((key: string) => {
    const filterKey = key as keyof SurcoucheV2Filters
    if (!exception.includes(key) && !Array.isArray(query[filterKey])) {
      surcoucheV2Filters[filterKey] = [query[filterKey]] as any
    }
  })
  if (autocompleteFilter) {
    surcoucheV2Filters.autocomplete = autocompleteFilter
  }
  if (Object.keys(surcoucheV2Filters).length === 0) {
    surcoucheV2Filters['isRunning'] = ['On'] // forced is running true on first load
  }
  return surcoucheV2Filters
}

export function getCurrentSearchFromQuery (query: SurcoucheV2Query) : SurcoucheV2QueryModel {
  let filteredQuery: SurcoucheV2QueryModel = {}
  const filters: SurcoucheV2Query = query
  const stringFilters = ['baseline', 'country', 'stratLevel', 'alertCategory', 'user']
  const notArrayParams = ['search']

  Object.keys(filters).forEach((filterKey: string) => {
    const key = filterKey as keyof SurcoucheV2Query
    const value = filters[key]

    if (!value || value.length === 0) {
      delete filters[key]
    } else {
      if (!notArrayParams.includes(key)) {
        if (typeof value === 'string') {
          filteredQuery[key] =
            stringFilters.includes(key)
              ? value
              : labelToBoolean(value)
        } else {
          filteredQuery[key] = value.map((elem: string) => {
            if (stringFilters.includes(filterKey)) {
              return elem
            } else {
              return labelToBoolean(elem)
            }
          }).join(',')
        }
      } else {
        filteredQuery[key] = filters[key] as string
      }
    }
  })
  console.log(filteredQuery)
  return filteredQuery
}

export function labelToBoolean (label: string): string {
  return (label === 'On' || label === 'Yes').toString()
}

export function getUsersFilter (users: UserModel[]) : { id: string, mail: string }[] {
  let parsedUsers: { id: string, mail: string }[] = []
  users.map((user: UserModel) => {
    parsedUsers.push(
      { id: user.userId.toString(), mail: user.dvMail ?? user.mail }
    )
  })
  return parsedUsers
}

export function setupAutocompleteInsertionOrder (data: CollectionInstruction<DspInstruction>, dsp: InstructionDsp, type: EntityType) {
  let autocompleteFilter: AutocompleteFilter = null
  let retrievedIO: Array<DspInstruction> = []
  if (Object.keys(data).length > 0) {
    retrievedIO = Object.values(data)[0]
  }
  autocompleteFilter = {
    id: retrievedIO[0]?.id,
    // external id ?
    insertion_order_id: retrievedIO[0][getIoField(dsp) as KeysOf<Instruction>],
    group_name: retrievedIO[0]?.group_name,
    type: type
  }
  const entities: { entities: AutocompleteFilter[], type: EntityType, count: number } = {
    entities: [
      {
        group_name: autocompleteFilter.group_name,
        insertion_order_id: autocompleteFilter.insertion_order_id,
        id: autocompleteFilter.id,
        type: type
      }
    ],
    type: type,
    count: 1
  }
  return { autocompleteFilter, entities }
}
