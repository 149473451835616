
import { defineComponent, PropType } from 'vue'
import ExpansionsFilters from '@/components/SurcoucheV2/ExpansionsFilters.vue'
import AutocompleteSearchBar from '../Common/AutocompleteSearchBar.vue'
import store from '../../../store'
import { surcoucheV2Store } from '../../../store/modules/surcoucheV2Store'
import {
  AutocompleteFilter,
  QueryFilter, SimpleUser,
  SurcoucheV2Query
} from '../../../types/surcouche_v2_types'
import { userHexColor } from '../../../utils/commonUtils'

// load the store module dynamically only when needed
if (!store.state.surcoucheV2Store) store.registerModule('surcoucheV2Store', surcoucheV2Store)

export default defineComponent({
  name: 'SurcoucheV2Toolbar',
  components: {
    ExpansionsFilters,
    AutocompleteSearchBar
  },
  props: {
    result: {
      required: false,
      type: Object as PropType<AutocompleteFilter>
    },
    search: {
      required: false
    },
    users: {
      type: Array as PropType<SimpleUser[]>,
      required: false
    },
    value: {
      type: Object as PropType<QueryFilter>,
      required: true
    },
    dsp: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      resultSync: this.result,
      defaultUserValue: [],
      isRunning: ['On', 'Off'],
      hasAlert: ['On', 'Off'],
      isExpand: false,
      items: [
        { title: 'Bulk Add' },
        { title: 'Decks' }
      ]
    }
  },
  mounted: function () {
    this.defaultUserValue = null
    this.resultSync = this.result
  },
  methods: {
    setUserColor (user: string) : string {
      console.log(user)
      if (user === undefined || !user[0]) {
        return userHexColor('All')
      }
      return userHexColor(user[0])
    },
    onSelect (data: string) {
      this.defaultUserValue.push(data)
      this.$emit('userSelected', this.defaultUserValue)
    },
    getItem (item: AutocompleteFilter) {
      console.log(item)
      this.resultSync = item
    },
    reloadPage () {
      const { autocomplete, ...data } = this.value as QueryFilter
      if (autocomplete) {
        data.search = autocomplete?.insertion_order_id
      }
      this.$emit('refresh-data', data)
    },
    searchDeleted () {
      (this.value as QueryFilter).search = ''; // reset search value so it starts over
      (this.value as QueryFilter).autocomplete = null // reset autocomplete value so it starts over
      this.$emit('input', { ...this.value as QueryFilter })
    },
    emitInputFilter (key: keyof QueryFilter, value: string | number | string[] | AutocompleteFilter) {
      this.$emit('input', { ...this.value as QueryFilter, [key]: value })
    },
    emitInputFull (event: SurcoucheV2Query) {
      console.warn('event', event)
      this.$emit('input', event)
    },
    setAvatarValue (users: string[]) : string {
      console.log('users', users)
      if (!users || users.length === 0) {
        return 'All'
      } else {
        const nbUsers = users?.length
        const firstUser = users[0]

        if (nbUsers === 1) {
          return this.users.find(user => user.mail === firstUser).mail[0].toUpperCase()
        } else if (nbUsers > 1) {
          return `${this.users.find(user => user.mail === firstUser).mail[0].toUpperCase()} +${(nbUsers - 1)}`
        }
      }
    },
    setSelectedUsersMail (users: string[]) {
      if (!users || users.length === 0) {
        return 'All'
      } else {
        return users.join(', ')
      }
    }
  },
  watch: {
    isExpand (bool : boolean) {
      this.$emit('expand-filter', bool)
    },
    dsp (dsp) {
      this.emitInputFull({})
    },
    defaultUser (value) {
      this.defaultUserValue = value
    },
    result (autocompleteResult) {
      console.log('autocompleteResult', autocompleteResult)
      this.resultSync = autocompleteResult
      this.emitInputFilter('autocomplete', autocompleteResult)
    },
    value (value) {
      console.log('value')
      console.warn(JSON.stringify(value))
    },
    resultSync (autocompleteResult) {
      console.log('result sync', autocompleteResult)
      this.$emit('update:result', autocompleteResult)
    }
  }
})
